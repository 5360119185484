import '../scss/main.scss';
/* Import ACF google maps script */
import '../js/modules/google_maps.js';

//let isotope =
require('isotope-layout');
require('isotope-packery');

// main.js
let imagesLoaded = require('imagesloaded');

// provide jQuery argument

// now use .imagesLoaded() jQuery plugin

(function($) {
    "use strict";
    $(document).ready(function() {

        /*------------------------------------------------------------------------*/
        /* Variables
        /*------------------------------------------------------------------------*/

        // Performance caching of jQuery objects
        let $_window = $(window);
        let $_htmlBody = $("html, body");
        // Extending search
        let searchToggler = $('#wpk_search-form_toggler');
        let searchForm = $('#wpk_homepage__search-form');
        let searchInput = $('#wpk_homepage__search-input');
        let searchIsExtended = false;
        /* Main slider on front-page */
        let mainSlider = $('.wpk_front-page__main-slider');
        let secondarySlider = $('.wpk_front-page__secondary-slider');
        let isotopeHomepage = $('.wpk_homepage__isotope-grid');
        let isotopeCategoryPage = $('.wpk_category-page__isotope-grid');
        let isotopeCareerPage = $('.wpk_career-page__isotope-grid');
        let isotopeAboutPage = $('.wpk_about-page__isotope-grid');
        let isotopeExtender = $('.wpk_homepage-isotope__extender');
        /* Single page on front-page */
        let shopDropdown = $('.wpk_shop-dropdown');
        let sidebarPostWrapper = $('.wpk_sidebar__offer-wrapper');
        /*------------------------------------------------------------------------*/
        /* Functions
        /*------------------------------------------------------------------------*/

        /* Function checks if variable contains existing selector from DOM */
        $.fn.exists = function() {
            return this.length !== 0;
        };

        /*------------------------------------------------------------------------*/
        /* Header
        /*------------------------------------------------------------------------*/

        /*  Handle extending navigation */

        $('nav li ul').hide().removeClass('fallback');

        let width = $(window).width();
        /* Disable inline style after resizing window */

        $_window.resize(function() {
            width = $(window).width();
            if (width > 875) {
                $('.wpk_nav--max-width').removeAttr('style');
            }
        });
        if ($(window).width() > 1024) {

        }
        let navOpen = false;
        $('.wpk_main-nav .menu-item-has-children').hover(


            function() {
                if (!navOpen) {

                    $('.sub-menu', this).slideUp(0).slideDown(90);
                    navOpen = !navOpen;

                } else {
                    return
                }

            },
            function() {
                if (navOpen) {
                    navOpen = !navOpen;

                    $('.sub-menu', this).slideUp(90);
                }

            }

        );
        let clicked = false;
        $('a', '.wpk_main-nav .menu-item-has-children').click(function(event) {
            //event.preventDefault();

            if (!clicked) {
                $('.sub-menu', '.wpk_main-nav .menu-item-has-children').slideUp(0).slideDown(200);
                clicked = !clicked;
            } else {

                $('.sub-menu', '.wpk_main-nav .menu-item-has-children').slideUp(200);
                clicked = !clicked;
            }

        });
        $('.wpk_nav--toggle').on('click', function() {
            let marginTop = $('.wpk_nav-wrapper').height();
            let marginTopAdminBar = $('#wpadminbar').height();

            $('.wpk_nav--max-width').css({
                // Display navigation beneath navbar. Added WP Admin bar 
                top: marginTop + marginTopAdminBar
            });
            $('.wpk_nav--max-width').slideToggle();
        })


        /*------------------------------------------------------------------------*/
        /* Front page
        /*------------------------------------------------------------------------*/
        searchToggler.on('click', function() {

            if (searchIsExtended === false) {
                searchForm.slideToggle('slow');
                searchIsExtended = !searchIsExtended;
            } else if (searchInput.val() && searchIsExtended === true) {
                searchForm.submit();
            } else {
                searchForm.slideToggle('slow');
                searchIsExtended = !searchIsExtended;
            }

        });
        /* Main slider with featured images */

        if (mainSlider.exists()) {
            /* Initialize main slick slider */
            mainSlider.slick({
                centerPadding: '60px',
                slidesToShow: 1,
                arrows: true,
                nextArrow: '<i class="fal fa-arrow-to-right wpk_homepage-main-slider__right-icon"></i>',
                prevArrow: '<i class="fal fa-arrow-to-left wpk_homepage-main-slider__left-icon"></i>',
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                            autoplay: true,
                            autoplaySpeed: 5000,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                            autoplay: true,
                            autoplaySpeed: 5000,
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                            autoplay: true,
                            autoplaySpeed: 5000,
                        }
                    },
                ]
            });
        }

        /* Isotope grid */
        isotopeHomepage.imagesLoaded().progress(function() {
            isotopeHomepage.isotope({
                percentPosition: true,
                itemSelector: '.wpk_isotope-home__item',
                layoutMode: 'packery',

                packery: {
                    gutter: '.wpk_isotope-home__gutter',
                },

            });
        });

        $('.wpk_isotope-home__item').hover(
            function() {
                $('.wpk_homepage-isotope__feat-excerpt', this).slideToggle();
            }

        );

        isotopeExtender.on('click', function() {
            $('.wpk_homepage-isotope__feat-excerpt', this).slideToggle();
        });




        /* Secondary slider with featured shops */

        if (secondarySlider.exists()) {
            /* Initialize secondary slick slider */
            secondarySlider.slick({
                nextArrow: '<i class="fal fa-arrow-to-right wpk_homepage-secondary-slider__right-icon"></i>',
                prevArrow: '<i class="fal fa-arrow-to-left wpk_homepage-secondary-slider__left-icon"></i>',
                centerPadding: '60px',
                slidesToShow: $_themeVariables['secondarySliderFeaturedCount'],
                arrows: true,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 5000,
                responsive: [{
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4,
                            arrows: false,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            slidesToShow: 2,
                            autoplay: true,
                            autoplaySpeed: 5000,
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            arrows: false,
                            slidesToShow: 1,
                            autoplay: true,
                            autoplaySpeed: 5000,
                        }
                    },
                ]
            });
        }
        /*------------------------------------------------------------------------*/
        /* Category page grid
        /*------------------------------------------------------------------------*/

        isotopeCategoryPage.imagesLoaded().progress(function() {
            isotopeCategoryPage.isotope({
                percentPosition: true,
                itemSelector: '.wpk_isotope__item',
                masonry: {
                    gutter: '.wpk_isotope__gutter',
                }
            });
        });
        /*------------------------------------------------------------------------*/
        /* Career page grid
        /*------------------------------------------------------------------------*/
        isotopeCareerPage.imagesLoaded().progress(function() {
            isotopeCareerPage.isotope({
                percentPosition: true,
                itemSelector: '.wpk_isotope__item',
                masonry: {
                    gutter: '.wpk_isotope__gutter',
                }
            });
        });
        /*------------------------------------------------------------------------*/
        /* About page grid
        /*------------------------------------------------------------------------*/
        isotopeAboutPage.imagesLoaded().progress(function() {
            isotopeAboutPage.isotope({
                percentPosition: true,
                layoutMode: 'packery',
                itemSelector: '.wpk_isotope__alternative-item',
                originLeft: false,
                packery: {
                    rowHeight: '.wpk_isotope__alternative-row-height',
                    gutter: '.wpk_isotope__alternative-gutter'
                }
            });
        });
        /*------------------------------------------------------------------------*/
        /* Footer
        /*------------------------------------------------------------------------*/

        $('#wpk_scroll-handle').on('click', function() {
            $_htmlBody.animate({ scrollTop: 0 }, 800);
            return false;
        });
        /*------------------------------------------------------------------------*/
        /* Ajax dropdown
        /*------------------------------------------------------------------------*/


        shopDropdown.change(function() {

            let shopLink = shopDropdown.find(":selected").val();
            if (shopLink != 'default') {
                window.location.href = shopLink;

            }


        });



    });
})(jQuery);